<template>
  <v-row>
    <v-col cols="12" md="3">
      <AdminMenu></AdminMenu>
    </v-col>
    <v-col cols="12" md="9" class="adminBg">
      <v-layout wrap class="mt-3">
        <v-col cols="12">
          <h4>User Accounts Module</h4>
        </v-col>
      </v-layout>

      <v-layout wrap class="mt-3">
                <v-col cols="12" md="4">
                  <v-layout class="boxStat" style="border: 1px solid #ffd809;">
                    <v-col cols="12">
                      <v-layout>
                        <v-col cols="12">
                          <span class="ml-5">All Users</span>
                        </v-col>
                      </v-layout>
                      <v-layout>
                        <v-col cols="6"> 
                          <span class="text-h5 ml-5"><b>{{allUsers.length}}</b></span>
                        </v-col>
                        <v-col cols="3" align="right">
                          <v-btn small>
                            <v-icon size="20">mdi-chevron-right</v-icon>
                          </v-btn>
                        </v-col>
                      </v-layout>
                    </v-col>
                  </v-layout>
                </v-col>

                <v-col cols="12" md="4">
                  <v-layout class="boxStat" style="border: 1px solid #72BD61;">
                    <v-col cols="12">
                      <v-layout>
                        <v-col cols="12">
                          <span class="ml-5">Approved Users</span>
                        </v-col>
                      </v-layout>
                      <v-layout>
                        <v-col cols="6">
                          <span class="text-h5 ml-5"><b>{{approvedUsers.length}}</b></span>
                        </v-col>
                        <v-col cols="3" align="right">
                          <v-btn small @click="fetchApprovedUsers">
                            <v-icon size="20">mdi-chevron-right</v-icon>
                          </v-btn>
                        </v-col>
                      </v-layout>
                    </v-col>
                  </v-layout>
                </v-col>

                <v-col cols="12" md="4">
                  <v-layout class="boxStat" style="border: 1px solid #2776ED;">
                    <v-col cols="12">
                      <v-layout>
                        <v-col cols="12">
                          <span class="ml-5">Unapproved Users</span>
                        </v-col>
                      </v-layout>
                      <v-layout>
                        <v-col cols="6">
                          <span class="text-h5 ml-5"><b>{{unapprovedUsers.length}}</b></span>
                        </v-col>
                        <v-col cols="3" align="right">
                          <v-btn small @click="fetchUnapprovedUsers">
                            <v-icon size="20">mdi-chevron-right</v-icon>
                          </v-btn>
                        </v-col>
                      </v-layout>
                    </v-col>
                  </v-layout>
                </v-col>

              </v-layout>

      <v-layout wrap>
        <v-col cols="12">
          <v-layout wrap>
            <v-col cols="4">
              <v-dialog v-model="dialogSuccess" width="350" persistent>
                <v-card>
                  <v-card-text>
                    <v-layout>
                      <v-col cols="12" align="center">
                        <v-icon color="#72BD61" size="50"
                          >mdi-check-circle</v-icon
                        >
                      </v-col>
                    </v-layout>
                    <v-layout wrap>
                      <v-col class="text-h6" cols="12" align="center">
                        Account Created Successfully
                      </v-col>
                    </v-layout>
                    <v-layout wrap>
                      <v-col cols="12" align="center">
                        You have Successfully created an admin sub account.
                        Login details have been emailed to them accordingly
                      </v-col>
                    </v-layout>
                    <v-layout wrap>
                      <v-col cols="12" align="center">
                        <v-btn
                          class="buttonStyle"
                          @click="dialogSuccess = false"
                        >
                          Ok
                        </v-btn>
                      </v-col>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-col>
          </v-layout>
          <v-layout wrap class="whiteBg" v-if="userAccounts.length >= 1">
            <v-col cols="12">
              <v-layout wrap>
                <h5>All Users</h5>
              </v-layout>

              
              <v-layout wrap>
                <v-col cols="12">
                  <v-simple-table fixed-header height="300px">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            Name
                          </th>
                          <th class="text-left">
                            Email
                          </th>
                          <th class="text-left">
                            Role
                          </th>
                          <th class="text-left">
                            Status
                          </th>
                          <th class="text-left">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="user in userAccounts" :key="user.userId">

                          <td style="font-size:12px;">
                              {{user.name}}
                          </td>

                          <td style="font-size:12px;">
                              {{user.email}}
                          </td>

                          <td style="font-size:12px;">
                              {{user.Role.name}}
                          </td>
                        
                          <td style="font-size:12px;">
                              <span v-if="user.isActive"  style="color:green;"> Approved</span> <span style="color:red;" v-else> Not Approved</span> 
                          </td>
                          <td style="font-size:12px;">
                                <v-btn rounded small color="#F12B2C" dark @click="openViewDialog(user)"
                                  >View</v-btn
                                >
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-layout>
            </v-col>
          </v-layout>
        </v-col>
      </v-layout>

      <v-dialog v-model="viewDialog" width="550">
                <v-card>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                            <p> 
                                <v-text-field label="Full Name" dense :value="user.name" readonly>
                                </v-text-field>
                            </p>
                            <p>
                                <v-text-field label="Email" dense :value="user.email" readonly>
                                </v-text-field> 
                            </p>
                            <p>
                                <v-text-field label="Phone" dense :value="user.phoneNumber" readonly>
                                </v-text-field>
                            </p>
                      </v-col>
                    </v-row>

                    <v-row v-if="user.Role.name !== 'User'">
                      <v-col cols="12">
                            <p> 
                                <v-text-field label="Company Name" dense :value="extraDetail.companyName" readonly>
                                </v-text-field>
                            </p>
                            <p>
                                <v-text-field label="Company Number" dense :value="extraDetail.companyNumber" readonly>
                                </v-text-field> 
                            </p>
                            <p>
                                <v-textarea label="Company Description" readonly :value="extraDetail.description"></v-textarea>
                            </p>
                      </v-col>
                    </v-row>

                     <v-row>
                        <v-col cols="12">
                          <p class="mt-3">
                                <v-progress-linear
                                    class="mt-3"
                                    color="#7CBF46"
                                    v-show="loader"
                                    :indeterminate="true"
                                ></v-progress-linear>
                          </p>

                          <p class="mt-1" align="center">
                                <v-btn small color="green" dark v-if="!user.isActive" @click="activateAccount(user.userId)">Approve Account</v-btn>
                                <v-btn small color="red" dark v-else @click="deactivateAccount(user.userId)">Block Account</v-btn>
                            </p>
                        </v-col>
                     </v-row>

                  </v-card-text>
                </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>
import AdminMenu from "../others/AdminMenu";
export default {
  name: "AdminUserAccountModule",
  components: {
    AdminMenu,
  },
  data() {
    return {
      dialogCreateAccount: false,
      viewDialog:false,
      loader:false,
      dialogSuccess: false,
      userAccounts: [],
      allUsers:[],
      unapprovedUsers: [],
      approvedUsers: [],
      roles: [],
      user:{
        Role: {
          name:''
        }
      },
      systemSetting: {
        systemCurrency: "₦",
      },
      extraDetail:{}
    };
  },
  created() {
    this.fetchUsers();
  },
  methods: {
    fetchUsers(){
      this.userAccounts = [];
        this.$http
        .get(`${process.env.VUE_APP_URL}users`)
        .then((response) => {
          this.allUsers = response.data.data;
          response.data.data.forEach(element => {
              if(element.Role.name !== 'professional')
              {
                  this.userAccounts.push(element);
              }

              if(element.isActive == false) {
                this.unapprovedUsers.push(element);
              }

              if(element.isActive == true) {
                this.approvedUsers.push(element);
              }
          });
        });
    },

    fetchApprovedUsers(){
      this.userAccounts = [];
        this.$http
        .get(`${process.env.VUE_APP_URL}users`)
        .then((response) => {
          response.data.data.forEach(element => {
              if(element.isActive == true) {
                this.userAccounts.push(element);
              }
          });
        });
    },

    fetchUnapprovedUsers(){
      this.userAccounts = [];
        this.$http
        .get(`${process.env.VUE_APP_URL}users`)
        .then((response) => {
          response.data.data.forEach(element => {
              if(element.isActive == false) {
                this.userAccounts.push(element);
              }
          });
        });
    },
    createSubAccount() {
      this.dialogCreateAccount = false;
      this.dialogSuccess = true;
    },
    openViewDialog(user) {
        this.user = user;
        this.viewDialog = true;
        if(user.Manufacturer !== null) {
          this.extraDetail = user.Manufacturer;
        }

        if(user.Distributor !== null) {
          this.extraDetail = user.Distributor;
        }

        if(user.Retailer !== null) {
          this.extraDetail = user.Retailer;
        }
    },

    activateAccount(userId){
        this.loader =true;
        this.unapprovedUsers = [];
        this.approvedUsers = [];
        this.user = {};

        const data = {
            isActive: true
        };

        this.$http
        .put(`${process.env.VUE_APP_URL}user/activateaccount/` + userId, data)
        .then((response) => {
          this.loader =false;
          this.user = response.data.data;
          this.fetchUnapprovedUsers();
          this.viewDialog = false;
        });
    },

    deactivateAccount(userId){
        this.loader =true;
        this.unapprovedUsers = [];
        this.approvedUsers = [];
        this.user = {};

        const data = {
            isActive: false
        };

        this.$http
        .put(`${process.env.VUE_APP_URL}user/activateaccount/` + userId, data)
        .then((response) => {
          this.loader =false;
          this.user = response.data.data;
          this.fetchApprovedUsers();
        });
    }

  },
};
</script>
<style scoped>
.adminBg {
  background: #e5e5e5;
}

.whiteBg {
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px;
}

.boxStat {
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 5px;
}

.buttonStyle {
  background: linear-gradient(192.88deg, #111212 43.53%, #72bd61 90.69%);
  border-radius: 10px;
  color: #ffffff !important;
  font-size: 16px;
  padding-left: 45px !important;
  padding-right: 45px !important;
}
</style>
